export const TEXTS = {
    title:'Forget about paying for transaction-based accounting services!',
    description: 'Sol.online is the first in the UAE outsource accounting solution with a fixed monthly fee, that does not depend on your transactions volume.',
    tiles: [{
        amount: '390',
        text: 'AED/m, NO VAT, small business',
    }, {
        amount: '790',
        text: 'AED/m, NO VAT, up to 3 employees',
    }, {
        amount: '3 900',
        text: 'AED/m, full assistance',
    }],
    blockWithPoints: {
        name: 'Why Choose Us?',
        title: "More than 100 entrepreneurs have joined us to experience lightness and transparency of accounting with us! Be one of them!",
        points: [{
            title: 'Pay a Fixed Monthly Fee,',
            description: "that doesn’t depend on transactions volume, because Sol.online is already integrated with banks and government services.",
        },
        {
            title: 'Get Timely Responses',
            description: "and issue resolutions within the period set and agreed by our Service Level Agreement (SLA). We value your time and we guarantee that we shall start working on your questions within 15 minutes from the request.",
        },
        {
            title: 'Don\'t Worry about Complience with UAE Legislation.',
            description: "Our team has amassed large experience in working with various types of companies in the UAE. Every year, a lot of major and minor amendments are made to laws. Therefore, we are here to stay informed and prevent you from any penalties.",
        },
        {
            title: 'Enjoy Dedicated Support.',
            description: "⁠⁠Effortlessly send documents and communicate with our accounting team via your personal account, WhatsApp or Telegram.",
        }]
    },
    blockWithSlider: {
        name: 'How Does It Work?',
        title: 'Getting started with Sol.online is easy:',
        slides: [{
            icon: 'user',
            title: 'Quick and Free Sign-Up',
            description: "Joining us takes just a few minutes. Leave your contacts in form below, and you're on your way to a headache-free accounting solution for your business.", 
        }, {
            icon: 'chart',
            title: 'Let us Find the Best Solution for You',
            description: "Share your business problems and needs and we'll provide the easiest and most cost-effective solution.", 
        }, {
            icon: 'discount',
            title: 'Take a Look at Your Offer',
            description: "We will send you a personalized commercial offer based on your goals and your business needs.", 
        }, {
            icon: 'bars',
            title: 'Time for Other Important Tasks',
            description: "Don’t worry about accounting and taxes, we will deal with it for you! However, you will always be able to fully control the work of your accountants team via your chat.", 
        }]
    },
    form: {
        title: 'Get Started on Your Journey to headache-free accounting!',
        description: "Experience the revolution in accounting with a free 30-day trial period!",
        enquire: 'Start it now!',
        notValitPhone: 'Phone is not valid',
        buttonText: 'Submit',
        requiredPhoneText: 'The phone is required',
        requiredNameText: 'The name is required',
    },
};
