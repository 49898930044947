import { useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import { PhoneNumberUtil } from 'google-libphonenumber';
import 'react-international-phone/style.css';

import { TEXTS } from '../../texts';
import './Form.css';

const phoneUtil = PhoneNumberUtil.getInstance();

const TELEGRAM_CHAT_ID = '@SolExpertForm';
const TELEGRAM_BOT_TOKEN = '7037231599:AAHXTCI6k74LafcN4wE3NyKVmnfNsTOptDI';
const API = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;

const isPhoneValid = ({phone}) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const Form = ({refObj}) => {
  const [phone, setPhone] = useState({});
  const [name, setName] = useState('');
  const [status, setStatus] = useState({
    emptyName: false,
    emptyPhone: false,
  });  
  const [success, setSuccess] = useState(false);
  const [isValidPhone, setValidPhone] = useState(true);

  const onSubmit = (async (e) => {
    e.preventDefault();
    if (!name || phone.meta.inputValue.trim() === phone.phone.trim() || !phone.phone) {
      setStatus({...status, emptyName: !name, emptyPhone: phone.meta.inputValue.trim() === phone.phone || !phone.phone})
      return;
    } else if (!isPhoneValid(phone)) {
      setValidPhone(isPhoneValid(phone));
      return;
    }
    
    try {
      const response = await fetch(API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: TELEGRAM_CHAT_ID,
          text: `Name: ${name}\n\nCountry: ${phone.meta.country.name}\nPhone: ${phone.meta.inputValue}`,
        })
      });
      if (response.ok) {
        setName('');
        setPhone({phone: ''});
        setSuccess(true);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event':'successfullysubmit'});
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.log('--', error);
    }
  })

  return (
    <div className="Form" ref={refObj}>
      {success 
        ? <div styles={{color: '#18ff28'}}>Sent. We’ll contact you soon</div> 
        : <form className="FormWrapper" onSubmit={onSubmit}>
          <div className="inputWrap">
            <input name="Name" value={name} className={"input " + (status.emptyName ? 'bad' : '')} placeholder='Name' onChange={e => {
                setStatus({...status, emptyName: false});
                setName(e.target.value);
              }}/>
            {status.emptyName && <span>{TEXTS.form.requiredNameText}</span>}
          </div>
          <div className="inputWrap">
            <PhoneInput
              value={phone.phone}
              className={"phone " + (status.emptyPhone || !isValidPhone ? 'bad' : '')}
              defaultCountry='us'
              required
              onChange={(phone,meta) => {
                setStatus({...status, emptyPhone: false});
                setPhone({phone, meta});
              }}
            />
            {status.emptyPhone && <span>{TEXTS.form.requiredPhoneText}</span>}
            {!isValidPhone && <span>{TEXTS.form.notValitPhone}</span>}
          </div>
          <button type='submit' className="submit">Submit</button>
        </form>
      }
    </div>
  );
};

