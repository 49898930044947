import {useRef} from 'react';
import './App.css';
import { Form } from './components/Form';

const App = () => {
  const ref = useRef(null);

  return (
    <Form refObj={ref}/>
  );
};

export default App;
